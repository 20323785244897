// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analysis-js": () => import("./../../src/pages/analysis.js" /* webpackChunkName: "component---src-pages-analysis-js" */),
  "component---src-pages-archetypes-js": () => import("./../../src/pages/archetypes.js" /* webpackChunkName: "component---src-pages-archetypes-js" */),
  "component---src-pages-caregiver-js": () => import("./../../src/pages/caregiver.js" /* webpackChunkName: "component---src-pages-caregiver-js" */),
  "component---src-pages-creator-js": () => import("./../../src/pages/creator.js" /* webpackChunkName: "component---src-pages-creator-js" */),
  "component---src-pages-destroyer-js": () => import("./../../src/pages/destroyer.js" /* webpackChunkName: "component---src-pages-destroyer-js" */),
  "component---src-pages-fool-js": () => import("./../../src/pages/fool.js" /* webpackChunkName: "component---src-pages-fool-js" */),
  "component---src-pages-game-js": () => import("./../../src/pages/game.js" /* webpackChunkName: "component---src-pages-game-js" */),
  "component---src-pages-generic-js": () => import("./../../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innocent-js": () => import("./../../src/pages/innocent.js" /* webpackChunkName: "component---src-pages-innocent-js" */),
  "component---src-pages-king-js": () => import("./../../src/pages/king.js" /* webpackChunkName: "component---src-pages-king-js" */),
  "component---src-pages-lover-js": () => import("./../../src/pages/lover.js" /* webpackChunkName: "component---src-pages-lover-js" */),
  "component---src-pages-magician-js": () => import("./../../src/pages/magician.js" /* webpackChunkName: "component---src-pages-magician-js" */),
  "component---src-pages-orphan-js": () => import("./../../src/pages/orphan.js" /* webpackChunkName: "component---src-pages-orphan-js" */),
  "component---src-pages-page-js": () => import("./../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-result-js": () => import("./../../src/pages/result.js" /* webpackChunkName: "component---src-pages-result-js" */),
  "component---src-pages-sage-js": () => import("./../../src/pages/sage.js" /* webpackChunkName: "component---src-pages-sage-js" */),
  "component---src-pages-seeker-js": () => import("./../../src/pages/seeker.js" /* webpackChunkName: "component---src-pages-seeker-js" */),
  "component---src-pages-warrior-js": () => import("./../../src/pages/warrior.js" /* webpackChunkName: "component---src-pages-warrior-js" */)
}

